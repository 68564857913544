<template>
  <v-card>
    <v-card-title>
      <v-img contain max-height="150" src="../assets/crane.png"> </v-img>
    </v-card-title>

    <br />

    <h2 class="text-center">Facturas</h2>
  </v-card>
</template>

<script>
export default {
  name: 'Facturas',
};
</script>
